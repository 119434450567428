<template>
  <div class="">
    <h1>404</h1>
  </div>
</template>

<script>
export default {
  name: "xxx",
  components: {},
  data() {
    return {};
  },
};
</script>
<style lang='less' scoped>
img{
    width: 400px;
    height: 500px;
}
</style>